
import { computed, defineComponent, ref, onMounted, getCurrentInstance, watchEffect, onBeforeMount } from "vue";
import { useStore } from "vuex";
import TaskService from "@/core/services/car/TaskService";
import CustomerService from "@/core/services/car/CustomerService";
import { hideModal, removeModalBackdrop } from "@/core/helpers/dom";
import moment from "moment";
import MasterService from "@/core/services/car/MasterService";
import Multiselect from "@vueform/multiselect";
import LeadService from "@/core/services/car/LeadService";
import ReusableModal from '@/components/modals/forms/ReusableModal.vue';
import { ct } from '@/store/stateless/store';

interface IQuickCustomer {
  name: string;
  email: string;
  phone_number: string;
  lead_source: string;
  salesAgents: string;
}
// interface KTCreateCustomer extends Customer {}
interface TaskBodyParam {
  customer_id: number;
  lead_id: number;
  due_date: string;
  disposition_id: any;
  task_notes: string;
  agent: number;
  lost_lead_reason_id: number;
  // closed_lead: any | null;
}
export default defineComponent({
  name: "add-task-modal",
  components: { Multiselect, ReusableModal },
  props: {
    taskDetails: Array,
    customer: Object,
    lead: {
      type: Object,
      required: false
    },
    lead_id: {
      type: Number,
      required: false
    },
    leads: {
      type: Object,
      required: false
    },
    disabledButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    // const taskDetailsArr = ref(props.taskDetails);
  const inst = getCurrentInstance()

    let customerResponseValue = ref<any>("");
    let lostReasonValue = ref<string>("");
    let dueDateAt = ref<any>();
    let dueTimeAt = ref<string>("");
    let taskParameters = {} as TaskBodyParam;
    taskParameters.lost_lead_reason_id = -1;

    const formRef = ref<null | HTMLFormElement>(null);
    const addTaskModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let closedLead = ref(null);
    let formData = ref<IQuickCustomer>({
      name: "",
      email: "",
      phone_number: "",
      lead_source: "",
      salesAgents: "",
    });
    const isRequired = ref(false);
    const isRequiredFollowUp = ref(false);
    const isRequiredNotes = ref(false);
    const isRequiredResponse = ref(false);
    let isTaskFormValid = ref<boolean>(true);
    const popoverValue = ref<Array<number>>([11, 17]);
    // const formData = ref({});
    const store = useStore();

    // const route = useRoute();
    // const router = useRouter();

    const resetRule = () => {
      taskRules.value.disposition_id.show = false;
      taskRules.value.lost_lead_reason.show = false;
      taskRules.value.followup_time.show = false;
      taskRules.value.closed_lead.show = false;
    }

    const taskRules = ref({
      disposition_id: {
        show: false,
        message: "Please select the Customer Response"
      },
      lost_lead_reason: {
        show: false,
        message: "Please enter Lost Lead Reason"
      },
      followup_time: {
        show: false,
        message: "Please select Follow up due date & time"
      },
      task_notes: {
        show: false,
        message: "Please enter Notes"
      },
      closed_lead: {
        show: false,
        message: "Please select closed lead option"
      }
    });
    /* const resetFormValue = () => {
      formData.value.name="";
      formData.value.email="";
      formData.value.phone_number="";
      formData.value.lead_source="";
      formData.value.salesAgents="";

    }*/

    
    const dueDateTime = (format,hours='')=>{
      return (hours) ? moment().add(hours,'hours').format(format)
                     : moment().format(format);
    }

    const onCustomerResponseChange = (event) => {
      resetRule();

      taskParameters.disposition_id = event;
      customerResponseValue.value = event;

      if (taskParameters.disposition_id != 8) {
        closedLead.value = null;
        // taskParameters.closed_lead = null;
        taskRules.value.closed_lead.show = false;
      }
      /**
       * Adding 1 hour to the current date time
       */
       if([8, 11, 17].includes(customerResponseValue.value)){
        dueTimeAt.value = dueDateTime('H:mm');
        dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm')
      }else{
        dueTimeAt.value = dueDateTime('H:mm','1');
        dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
      }


      taskReason.value.value = null;
      Promise.all([MasterService.getTaskReasons({ task_status: event })]).then(
        (data) => {
          // taskReason.value.options = null;
        }
      );
     
    };
    const onLostLeadResponseChange = (event) => {
      taskParameters.lost_lead_reason_id = event;
      taskRules.value.lost_lead_reason.show = false;
    };
    let userTaskReason = computed(() => {
      return store.getters.getUserTaskReasons;
    });
    const taskReason = ref({
      placeholder: "Please Select Lost Reason",
      value: null,
      options: userTaskReason,
      searchable: true,
      clearOnSelect: true
    });

    onMounted(() => {
        dueTimeAt.value = dueDateTime('H:mm','1');
        dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');

      // setTimeout(() => {
      //   const responseFromCustomerRef:any = inst?.refs.responseFromCustomerRef
      //   responseFromCustomerRef.focusInput()
      // }, 1000);
    })
    const getDueDateTime = (dateTime) => {
      // var dueDateTime = new Date(dateTime);
      dueTimeAt.value = dueDateTime('H:mm','1');
      dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
      taskParameters.due_date = dueDateTime('YYYY-MM-DD HH:mm','1');;
        // dueDateTime.getFullYear() +
        // "-" +
        // (dueDateTime.getMonth() + 1) +
        // "-" +
        // dueDateTime.getDate() +
        // " " +
        // moment(dueDateTime.toLocaleTimeString(), "hh:mm A").format("HH:mm");
        taskRules.value.followup_time.show = false;
    };

    const closeButton = ref()

    let notes = ref<string>("");
    const addTask = (lead) => {
      isTaskFormValid.value = true;
      loading.value = true;

      taskParameters.task_notes = notes.value;
      taskParameters.agent = lead.agent;
      taskParameters.lead_id = lead.latest_task.lead_id;
      taskParameters.customer_id = lead.customer_id;
      taskParameters.due_date = dueDateAt.value
        // dueDateAt.value == "" ? "" : taskParameters.due_date;
      validateTaskForm(lead);

      if (isTaskFormValid.value) {
        CustomerService.addLeadTask(store, taskParameters).then(
          (data: any) => {
            //   CustomerService.getCustomerDetailsNoRefresh(
            //   store,
            //   taskParameters.customer_id
            // );
            // TaskService.getLeadTaskList(taskParameters);
            // loading.value = false;
            // resetFormValue();
            // // hideModal(addTaskModalRef.value);
            // // window.location.reload();
            // // closeModal();
            // ct.currentLead = lead.latest_task.lead_id
            // closeButton.value.click()
            CustomerService.getCustomerDetailsNoRefresh(
              store,
              taskParameters.customer_id
            );
            TaskService.getLeadTaskList(taskParameters);
            // window.location.reload();
            // console.log({data})
            resetFormValue();
            loading.value = false;
            closeModal();
            const messages = ['Redundant lead deleted successfully', 'False lead deleted successfully']
            if(data &&  messages.includes(data.message)){
              window.location.reload()
            }
            
            if(closeButton.value){
              closeButton.value.click()
            }

          }
        ).catch((err) => {
          loading.value = false;
          resetFormValue();
          closeModal();
        });
      } else {
        focusField()
        loading.value = false;
      }

      // if (taskDetails.length > 0) {
      //   //
      //   taskParameters.agent = taskDetails[0].agent;
      //   taskParameters.lead_id = taskDetails[0].lead_id;
      //   taskParameters.customer_id = taskDetails[0].customer_id;
      // }
      // console.log(taskParameters); //
      // // console.log(lead);
      // if (
      //   taskParameters.disposition_id != -1 ||
      //   taskParameters.due_date != ""
      // ) {
      //   //
      //   if (taskParameters.disposition_id == 8) {
      //     if (taskReason.value.value) {
      //       if (taskParameters.lost_lead_reason_id != -1) {
      //         Promise.all([
      //           CustomerService.addLeadTask(store, taskParameters),
      //         ]).then((data) => {
      //           CustomerService.getCustomerDetails(
      //             store,
      //             taskParameters.customer_id,
      //             false
      //           );
      //           TaskService.getLeadTaskList(taskParameters);
      //         });
      //         resetFormValue();
      //         hideModal(addTaskModalRef.value);
      //       }
      //     } else {
      //       isRequired.value = true;
      //     }
      //   } else {
      //     if (dueDateAt.value && notes.value && customerResponseValue.value) {
      //       Promise.all([
      //         CustomerService.addLeadTask(store, taskParameters),
      //       ]).then((data) => {
      //         CustomerService.getCustomerDetails(
      //           store,
      //           taskParameters.customer_id,
      //           false
      //         );
      //         TaskService.getLeadTaskList(taskParameters);
      //       });
      //       resetFormValue();
      //       hideModal(addTaskModalRef.value);
      //     } else {
      //       if (!dueDateAt.value) {
      //         isRequiredFollowUp.value = true;
      //       }
      //       if (!notes.value) {
      //         isRequiredNotes.value = true;
      //       }
      //       if (!customerResponseValue.value) {
      //         isRequiredResponse.value = true;
      //       }
      //     }
      //   }
      // }
    };

    const validateTaskForm = (lead) => {
      if (taskParameters.disposition_id == ""  || taskParameters.disposition_id == undefined) {
        taskRules.value.disposition_id.show = true;
        isTaskFormValid.value = false;
      }
      if (
        taskParameters.disposition_id == 8 ||
        taskParameters.disposition_id == 11
      ) {
        if (
          taskParameters.lost_lead_reason_id == null ||
          taskParameters.lost_lead_reason_id == -1
        ) {
          taskRules.value.lost_lead_reason.show = true;
          isTaskFormValid.value = false;
        }
      }

      if (
        taskParameters.disposition_id == 12 ||
        taskParameters.disposition_id == 13
      ) {
        if (taskParameters.task_notes == "") {
          taskRules.value.task_notes.show = true;
          isTaskFormValid.value = false;
        }
        if (taskParameters.task_notes.length > 255) {
          taskRules.value.task_notes.show = true;
          taskRules.value.task_notes.message =
            "Max  255 characters are allowed";
          isTaskFormValid.value = false;
        }
      }

      if (
        (dueDateAt.value == "" ||
          dueDateAt.value == null ||
          dueDateAt.value == undefined) &&
        taskParameters.disposition_id != 8 &&
        taskParameters.disposition_id != 11 &&
        taskParameters.disposition_id != 17 &&
        taskParameters.disposition_id != 18
      ) {
        taskRules.value.followup_time.show = true;
        isTaskFormValid.value = false;
      }
      // console.log(lead.is_show_closed_lead, taskParameters.closed_lead);
      if (
        taskParameters.disposition_id == 8 &&
        lead.is_show_closed_lead &&
        closedLead.value == null
      ) {
        taskRules.value.closed_lead.show = true;
        isTaskFormValid.value = false;
      }

      // validateDisposition.value =
      //   taskParameters.disposition_id == "" ? true : false;

      // validateDueDate.value =
      //   taskParameters.due_date == "" || dueDateAt.value == "" ? true : false;
    };
    const resetFormValue = () => {
      customerResponseValue.value = "-1";
      lostReasonValue.value = "-1";
      taskReason.value.value = null;
      notes.value = "";
      dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
      dueTimeAt.value = dueDateTime('HH:mm','1');
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      } else {
        // make API call for adding customer
        //
        CustomerService.addLeadTask(store, formData.value);
        resetFormValue();
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;
          }, 2000);
        } else {
          return false;
        }
      });
    };
    const lostReason_disabled = computed(() => {
      return customerResponseValue.value == "8" ||
        customerResponseValue.value == "11"
        ? false
        : true;
    });

    let userTaskList = computed(() => {
      return store.getters.getUserTaskResponse;
    });

    const userStatus = computed(() => {
      return userTaskList.value.statuses
    })

    const dealStatus = computed(() => {
      return userTaskList.value.deal_statuses
    })

    const cancelStatus = computed(() => {
      return userTaskList.value.cancel_statuses;
    });

    const policyStatus = computed(() => {
      return userTaskList.value.policy_task_statuses;
    });

    const userTask = ref({
      placeholder: "Please Select Response",
      value: null,
      options: userTaskList.value.statuses,
      searchable: true,
      clearOnSelect: true,
    });

    const dealTask = ref({
      placeholder: "Please Select Response",
      value: null,
      options: dealStatus,
      searchable: true,
      clearOnSelect: true,
    });

    const cancelTask = ref({
      placeholder: "Please Select Response",
      value: null,
      options: cancelStatus,
      searchable: true,
      clearOnSelect: true,
    });

    const policyTaskStatus = ref({
      placeholder: "Please Select Response",
      value: null,
      options: policyStatus,
      searchable: true,
      clearOnSelect: true
    });
    const disableDueDate = computed(() => {
      // return customerResponseValue.value == "17" ||
      //   customerResponseValue.value == "18"
      //   ? true
      //   : false;
      return [8, 11, 17].includes(customerResponseValue.value)
    });
    const today = computed(() => {
      var ddate = new Date();
      ddate.setDate(ddate.getDate()-1);
      return new Date(ddate).getTime();
    })
    const disabledDate = (time: Date) => {
      
      return time.getTime() < today.value;
    };

    const changeCloseLead = (event) => {
      // taskParameters.closed_lead = event.target.value;
      closedLead.value = event.target.value;
      // console.log("changeCloseLead", taskParameters);
      // console.log("event.target.value", event.target.value);
      taskRules.value.closed_lead.show = false;
    };

    const updateCloseLead = (lead) => {
      // console.log("taskParameters", taskParameters);
      isTaskFormValid.value = true;
      loading.value = true;

      taskParameters.task_notes = notes.value;
      taskParameters.agent = lead.agent;
      taskParameters.lead_id = lead.id;
      taskParameters.customer_id = lead.customer_id;
      taskParameters.due_date = dueDateAt.value
        // dueDateAt.value == "" ? "" : taskParameters.due_date;
      validateTaskForm(lead);
      // if(taskParameters.closed_lead == '1' && isTaskFormValid.value)
      // {
      //   //show popup
      // }

      if (isTaskFormValid.value) {
        Promise.all([LeadService.updateClosedLead(taskParameters)]).then(
          (data) => {
            CustomerService.getCustomerDetails(
              store,
              taskParameters.customer_id,
              false
            );

            TaskService.getLeadTaskList(taskParameters);
            loading.value = false;
            resetFormValue();
            // hideModal(addTaskModalRef.value);
            // closeModal();
            if(closeButton.value){
              closeButton.value.click()
            }
          }
        );
      } else {
        loading.value = false;
      }
    };


    const resetAddTask = () => {
      ct.fp = false
      resetFormValue();
      userTask.value.value = null;
      policyTaskStatus.value.value = null;
      dealTask.value.value = null;
      cancelTask.value.value = null;
      taskRules.value.disposition_id.show = false;
      taskRules.value.lost_lead_reason.show = false;
      taskRules.value.followup_time.show = false;
      taskRules.value.task_notes.show = false;
      notes.value = "";
      dueDateAt.value = moment().format('YYYY-MM-DD HH:mm');
      dueTimeAt.value = moment().format('HH:mm');
      taskParameters.due_date = "";
      taskParameters.disposition_id = "";
      taskParameters.task_notes = "";
      taskParameters.lost_lead_reason_id = -1;
      if(closeButton.value){
        closeButton.value.click()
      }
    }

    const isOpenDialog = ref(false);
    const itemLead = ref<any>(null);
    const modalTitle = ref<string>('');
    const modalMessage = ref<string>('');
    const openModal = (item, isAddTask) => {
      itemLead.value = {
        lead: item,
        isAdd: isAddTask
      };
      modalTitle.value = 'Task Details';
      modalMessage.value = isAddTask ? 'Are you sure this will delete the lead permanently?' : 'Are you sure you want to close the lead';
      isOpenDialog.value = true;
    }

    const processData = (item) => {
      if(item.isAdd) {
        addTask(item.lead);
      }

      if(!item.isAdd) {
        updateCloseLead(item.lead);
      }
    }

    const closeModal = () => {
      itemLead.value = null;
      isOpenDialog.value = false;
    };

    const closingLeads = computed(() => {
      if(!popoverValue.value.includes(customerResponseValue.value)) {
        return true
      }
      if(customerResponseValue.value === 11 && taskReason.value.value) {
        return false;
      } 

      if (customerResponseValue.value === 17 && !taskReason.value.value) {
        return false;
      }
      return true;
    })


    const disableToday = computed(() => {
      const myDate = new Date(today.value)
      myDate.setDate(myDate.getDate() + 1)
      return moment(myDate).format('YYYY-MM-DD')
    })

    const toTaskNotes = () => {
      const notesRef:any = inst?.refs.notesRef
        notesRef.focus()
        if(dueDateAt.value) {
          taskRules.value.followup_time.show = false
          taskParameters.due_date = moment(dueDateAt.value).format("YYYY-MM-DD HH:mm");
        }
    }

    const focusField = () => {
      const { disposition_id, lost_lead_reason, followup_time, task_notes } = taskRules.value

      if(disposition_id.show && !taskParameters.disposition_id) {
        const responseFromCustomerRef:any = inst?.refs.responseFromCustomerRef
        responseFromCustomerRef.focusInput()
      }

      if(!disposition_id.show && lost_lead_reason.show) {
        const lostLeadRef:any = inst?.refs.lostLeadRef
        lostLeadRef.focusInput()
      }

      if(!disposition_id.show && followup_time.show) {
        const dueDatePicker:any = inst?.refs.dueDatePicker
        dueDatePicker.isFocus()
      }

      if(!disposition_id.show  && (!followup_time.show || !lost_lead_reason.show) && task_notes.show) {
        const notesRef:any = inst?.refs.notesRef
        notesRef.focus()
      }
    }

    const dateTimeBack = () => {
      const lostLeadRef:any = inst?.refs.lostLeadRef
      const responseFromCustomerRef:any = inst?.refs.responseFromCustomerRef
      if(!lostLeadRef.disabled) {
        lostLeadRef.focusInput()
      } else {
        responseFromCustomerRef.focusInput()
      }
    }

    const goBackTime = () => {
      const dueDatePicker:any = inst?.refs.dueDatePicker
      dueDatePicker.isFocus()
    }

    const toCancel = () => {
      const cancelRef:any = inst?.refs.cancelRef
      cancelRef.focus()
    }

    watchEffect(() => {
      if(ct.fp) {

       
        setTimeout(() => {
          const responseFromCustomerRef:any = inst?.refs.responseFromCustomerRef
          responseFromCustomerRef.focusSearch()
        }, 1000);
      }

      if(ct.fp && props.leads){
        // console.log(props.leads)
         const otherLeadStatus = props.leads.filter((x) => {
              if(![1,2].includes(x.lead_status_id))
                  return x;
          });

          let responses = store.getters.getUserTaskResponse?.statuses;
        
          if(responses && props.leads.length <= 1){
              // BND-2033 : filter out redudant lead if only single lead present
              responses = responses.filter(x => x.value != 17)
          }

          if(responses && otherLeadStatus.length > 0){
              // BND-2033 : filter out false lead if other leads are present
              responses = responses.filter(x => x.value != 11)
          }
  
          // console.log('responses',responses) 
          userTask.value.options = [];
          userTask.value.options = responses;
      }
    })

    
    const closingTime = (e) => {
      if(e.which === 27) {
        resetAddTask()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closingTime);
    })

    


    return {
      policyTaskStatus,
      closingTime,
      closeButton,
      ct,
      toCancel,
      goBackTime,
      dateTimeBack,
      focusField,
      toTaskNotes,
      disableToday,
      resetRule,
      closingLeads,
      processData,
      closeModal,
      openModal,
      modalMessage,
      modalTitle,
      itemLead,
      isOpenDialog,
      resetAddTask,
      updateCloseLead,
      changeCloseLead,
      popoverValue,
      closedLead,
      taskRules,
      disabledDate,
      disableDueDate,
      userTaskList,
      cancelStatus,
      userTask,
      dealTask,
      cancelTask,
      taskReason,
      userTaskReason,
      isRequired,
      isRequiredFollowUp,
      isRequiredNotes,
      isRequiredResponse,
      user,
      formData,
      submit,
      formRef,
      loading,
      addTaskModalRef,
      onCustomerResponseChange,
      onLostLeadResponseChange,
      getDueDateTime,
      addTask,
      notes,
      customerResponseValue,
      lostReasonValue,
      dueDateAt,
      dueTimeAt,
      taskParameters,
      lostReason_disabled,
    };
  },
});
